import React, { useEffect } from "react";
import Home from "./Component/Page/Home";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig, useQueryClient } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
import { Staking } from "./Component/Authentication/Context/StakingApp";
import { ToastContainer, toast } from "react-toastify";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Wallet from "./connect";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Header from "./Component/Shared/Header";
import routes from "./Router";
import Layout from "./Layout";
import Footer from "./Component/Shared/Footer";
import { Toaster } from 'react-hot-toast';
import NotFound from "./Component/Page/NotFound";
import axiosInstance from "./Config/axios";
import AiChatbot from "./Component/AiChatbot";
import { useSelector } from "react-redux";

const queryClient = new QueryClient()
function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  const userData = useSelector(state => state.auth.userData)

  const metadata = {
    name: "PVP casino",
    description: "Welcome to the PVP Solana Casino, It is future of METAVERSE and  GAMING",
    url: "https://pookar.ultrapay.info/",
    icons: [''],
  };

  const chains = [bsc, mainnet];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata, autoConnect: true });

  createWeb3Modal({ wagmiConfig, projectId, chains });


  const checkDepositTransaction = async () => {
    try {
      const response = await axiosInstance.get('/transaction/check-deposit-transaction');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    checkDepositTransaction();
  }, [])


  return (
    <div className="bg-gradient-to-tl from-[#26042a] relative from-10% via-[#1e0720] via-30% to-[#26042a] to-90%">
      {userData && userData?.id && <AiChatbot />}
      <WagmiConfig config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>

          <BrowserRouter>
            <Staking>
              {/* <Wallet> */}


              {/* <AutoConnect /> */}
              <Routes>
                <Route path="/" element={
                  <>
                    <Header />
                    <Home />
                  </>
                }></Route>



                {routes.map((route) => {
                  return (
                    <Route
                      key={route.id}
                      path={route.path}
                      exact={route.exact}
                      element={<Layout component={route.element} hideSidebar={route.hideSidebar} hideNavbar={route.hideNavbar} />}
                    />
                  );
                })}

                {/* Not Found */}
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />

              <ToastContainer />
            </Staking>
          </BrowserRouter>
          {/* </Wallet> */}
        </QueryClientProvider>
      </WagmiConfig>
      <Toaster toastOptions={
        {
          success: {
            style: {
              background: 'green',
              color: 'white',
              // fontSize: '15px',
              fontWeight: 'bold',
              padding: '1px 10px',
            },
            iconTheme: {
              primary: 'white',
              secondary: 'red',
            },
            icon: <CheckCircleIcon fontSize='small' style={{ color: 'white', }} />,
          },
          error: {
            style: {
              background: 'red',
              color: 'white',
              // fontSize: '14px',
              fontWeight: 'bold',
              padding: '1px 10px',
            },
            iconTheme: {
              primary: 'white',
              secondary: 'red',
            },
            icon: <ErrorOutlineIcon fontSize='small' style={{ color: 'white', }} />,
          },
          position: 'bottom-center',
        }
      } />
    </div>
  );
}

export default App;
