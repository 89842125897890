import { useState, useEffect } from 'react'

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import axiosInstance from "../../Config/axios"
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import './Chatbot.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const staticMessages = [
]

export default function ChatWidget() {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState(staticMessages)
  const [input, setInput] = useState('')
  const [currentTime, setCurrentTime] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const { userData } = useSelector(state => state.auth);
  const fetchAllMessages = async () => {
    try {
      const response = await axiosInstance.get('/assistant/get-messages');
      const { data: responseData } = response;
      if (responseData.status) {
        setMessages(responseData.data);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (!isOpen) return;
    fetchAllMessages()
  }, [isOpen])


  useEffect(() => {
    const updateTime = () => {
      const now = new Date()
      setCurrentTime(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
    }
    updateTime()
    const timer = setInterval(updateTime, 60000)
    return () => clearInterval(timer)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input) return;
    let message = input

    try {
      setIsLoading(true)
      setInput('')
      const response = await axiosInstance.post('/assistant/generate-voice', {
        message: message
      })
      setMessages([...messages, { id: messages.length + 1, message: input, createdAt: new Date().toISOString(), botResponseUrl: null, userName: userData.userName }])

      const { data: responseData } = response;
      if (responseData.status) {
        fetchAllMessages()
        setIsLoading(false)
        setIsAudioPlaying(true)

        let audioElement = document.createElement('audio');
        audioElement.src = responseData.data.url;

        setAudio(audioElement)
      } else {
        setInput(message)
        setIsLoading(false)
        toast.error(responseData.message);
      }
    } catch (error) {
      setInput(message)
      setIsLoading(false)
      toast.error(error.message);
    }
    // if (input.trim()) {
    //   setMessages([...messages, { id: messages.length + 1, role: 'user', content: input }])
    //   setInput('')
    // }
  }

  const handleAudioPlay = (url) => {
    let audioElement = document.createElement('audio');
    audioElement.src = url;
    setAudio(audioElement)
  }


  useEffect(() => {
    if (!audio) return;
    audio.play();
    const handleAudioEnd = () => {
      audio.pause();
      setIsAudioPlaying(false);
      setAudio('');

      // remove the audio element from the DOM
      audio.remove();
    };

    // Attach the 'ended' event listener
    audio.addEventListener("ended", handleAudioEnd);

    // Cleanup the event listener
    return () => {
      audio.removeEventListener("ended", handleAudioEnd);
    };
  }, [audio]);

  useEffect(() => {
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  } , [messages])

  return (
    <div style={{}} className={'rightchat'}>
      {isOpen ? (
        <div className="w-80 h-[500px] flex flex-col">
          <div className="flex flex-row items-center justify-between p-3 bg-purple-600 text-white rounded-t-lg">
            <div className="flex items-center gap-3">
              <div>
                <h3 className="font-semibold">PVP Casino</h3>
                <p className="text-xs text-purple-200">{currentTime}</p>
              </div>
            </div>
            <button
              variant="ghost"
              size="icon"
              onClick={() => setIsOpen(false)}
              className="text-white hover:bg-purple-700"
            >
              <CloseIcon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="flex-1 overflow-y-auto p-2  bg-white chat-container">
            {messages && messages.length == 0 && <div className='h-full w-full flex items-center justify-center'>
              No messages yet
            </div>}
            {messages && messages.length > 0 && messages.map((message) => (
              <div
                key={message.id}
                className={`flex text-xs ${!message.botResponseUrl ? 'flex-row-reverse' : 'flex-row'
                  }`}
              >
                <div className={`flex flex-col gap-1 ${!message.botResponseUrl ? 'items-end' : 'items-start'
                  }`}>
                  <div className="text-xs font-normal ">
                    {message.botResponseUrl ? 'PVP Casino' : (message.userName ? message.userName : 'You')}
                  </div>
                  <div
                    className={`text-xs rounded-lg break-words w-[250px] ${!message.botResponseUrl
                      ? 'bg-purple-600 text-white rounded-tr-none px-4 py-2'
                      : 'bg-gray-100 text-gray-900 rounded-tl-none px-2 py-2'
                      }`}
                  >
                    {message.botResponseUrl && <span onClick={() => handleAudioPlay(message.botResponseUrl)}>
                      <PlayArrowIcon className="h-4 w-4" />
                    </span>}

                    {message.message}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isLoading && <div className='flex items-center justify-center bg-white'>
            <div className='w-10 h-10 border-2 border-purple-600 border-t-purple-600 rounded-full animate-spin'></div>
          </div>}
          <div className="border-t p-2 bg-white">
            <form
              onSubmit={handleSubmit}
              className="flex w-full gap-2"
            >
              <input
                value={input}
                disabled={isLoading}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 py-1 px-1 focus:ring-0 text-black focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0"
              />
              <button disabled={isLoading} type="submit" size="icon" className="bg-purple-600 hover:bg-purple-700 py-1 px-2 rounded-full flex items-center justify-center">
                <SendIcon fontSize='12' className="h-2 w-2 text-white" />
                <span className="sr-only">Send message</span>
              </button>
            </form>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          size="icon"
          className="w-14 h-14 rounded-full bg-purple-600 hover:bg-purple-700 shadow-lg"
        >
          <MessageIcon className="h-6 w-6 text-white" />
          <span className="sr-only">Open chat</span>
        </button>
      )}
    </div>
  )
}

